const initialState = {
  login: [],
};
function fetchlogindata(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_DATA":
      // console.log(action.login);
      return {
        ...state,
        login: action.login,
      };
    default:
      return state;
  }
}

export default fetchlogindata;
