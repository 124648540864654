import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cookie from "cookie";

function Home() {
  const navigate = useNavigate();
  const login = useSelector((state) => state.logindata.login);
  const checkadminexists = async (user) => {
    const apiUrl = "https://api.jogkart.com/jogkart/admin/get_user.php";
    const requestBody = {
      user: user,
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const userData = await response.json();
        if (userData.status === "success" && userData.message === "success") {
          if (userData.userid === "yes") {
          } else {
            navigate(`/`);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCookie = (cookieName) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[cookieName];
  };
  useEffect(() => {
    const cookieValue = getCookie("jogwebtr");
    if (login.status === "success" || cookieValue) {
      checkadminexists(cookieValue);
    } else {
      navigate(`/`);
    }
  }, []);
  const [allnumberdata, setallnumberdata] = useState([]);
  const [stforallnum, setstforallnum] = useState(false);
  const fngetalldata = async () => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/admin/getalldatanum.php"
      );
      const result = await response.json();
      console.log(result[0]);
      setallnumberdata(result[0]);
      setstforallnum(true);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(stforallnum);

  useEffect(() => {
    if (!stforallnum) {
      fngetalldata();
    }
  }, []);
  // disable swipe option

  return (
    <div>
      <div>
        <div style={{}}>
          {/* total product */}
          <Link className="a_tag" to={"/totalproducts"}>
            <div>
              <div className="total-pro">
                <div className="inbox"></div>
              </div>
              <div className="text">Total Product</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.totalproduct}
              </div>
            </div>
          </Link>
          {/* total order */}
          <Link className="a_tag" to={"/totalorder"}>
            <div>
              <div className="total-order">
                <div className="inbox"></div>
              </div>
              <div className="text">Total Order</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.totalorder}
              </div>
            </div>
          </Link>
          {/* order shippid */}
          <Link className="a_tag" to={"/ordership"}>
            <div>
              <div className="order-ship">
                <div className="inbox"></div>
              </div>
              <div className="text">Order Shippid</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.ordershipped}
              </div>
            </div>
          </Link>
          {/* order out */}
          <Link className="a_tag" to={"/orderout"}>
            <div>
              <div className="order-out">
                <div className="inbox"></div>
              </div>
              <div className="text">Order Out</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.outfordelivery}
              </div>
            </div>
          </Link>
          {/* compelete order */}
          <Link className="a_tag" to={"/compelete"}>
            <div>
              <div className="complete-order">
                <div className="inbox"></div>
              </div>
              <div className="text">Compelete Order</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.deliverdorder}
              </div>
            </div>
          </Link>
          {/* cancelled order */}
          <Link className="a_tag" to={"/canceledorder"}>
            <div>
              <div className="cancel-order">
                <div className="inbox"></div>
              </div>
              <div className="text">Cancelled Order</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.calcelledorder}
              </div>
            </div>
          </Link>
          {/* return request */}
          <Link className="a_tag" to={"/returnrqst"}>
            <div>
              <div className="return-rqst">
                <div className="inbox"></div>
              </div>
              <div className="text">Return Request</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.returnrequest}
              </div>
            </div>
          </Link>
          {/* compelete return */}
          <Link className="a_tag" to={"/compeletereturn"}>
            <div>
              <div className="cmt-return">
                <div className="inbox"></div>
              </div>
              <div className="text">Compelete Return</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.compeltereturn}
              </div>
            </div>
          </Link>
          {/* cancelled return */}
          <Link className="a_tag" to={"/cancelledreturn"}>
            <div>
              <div className="cancelled-return">
                <div className="inbox"></div>
              </div>
              <div className="text">Cancelled Return</div>
              <div style={{ fontWeight: "600", color: "red" }}>
                {allnumberdata.cancelledreturn}
              </div>
            </div>
          </Link>
          {/* make new category */}
          {/* make subcategory */}
          {/* all categgory */}
          {/* all subcategory */}

          {/* add product */}
          <Link className="a_tag" to={"/makeproduct"}>
            <div>
              <div className="add-pro">
                <div className="inbox"></div>
              </div>
              <div className="text">Add Product</div>
            </div>
          </Link>
          {/* make dummy */}
          {/* visitores */}
          {/* custumer messages */}
          {/* total users */}
          {/* admin */}
          {/* all employee */}
          {/* all contacts */}
        </div>
      </div>
    </div>
  );
}

export default Home;
