import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import "./component/css/style.css";
import { Routes, Route, Link } from "react-router-dom";

import Home from "./component/Home";
import Makeproduct from "./component/Makeproduct";
import Login from "./component/Login";
import Totalproducts from "./component/Totalproducts";
import Totalorder from "./component/Totalorder";
import Ordership from "./component/Ordership";
import Orderout from "./component/Orderout";
import Compelete from "./component/Compelte";
import Canceledorder from "./component/Canceledorder";
import Returnrqst from "./component/Returnrqst";
import Compeletereturn from "./component/Compeletereturn";
import Cancelledreturn from "./component/Cancelledreturn";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/makeproduct" element={<Makeproduct />} />
        <Route exact path="/totalproducts" element={<Totalproducts />} />
        <Route exact path="/totalorder" element={<Totalorder />} />
        <Route exact path="/ordership" element={<Ordership />} />
        <Route exact path="/orderout" element={<Orderout />} />
        <Route exact path="/compelete" element={<Compelete />} />
        <Route exact path="/canceledorder" element={<Canceledorder />} />
        <Route exact path="/returnrqst" element={<Returnrqst />} />
        <Route exact path="/compeletereturn" element={<Compeletereturn />} />
        <Route exact path="/cancelledreturn" element={<Cancelledreturn />} />
      </Routes>
    </div>
  );
}

export default App;
