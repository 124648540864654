import { combineReducers, createStore } from "redux";

import login from "./reducers/login";

const store = createStore(
  combineReducers({
    logindata: login,
  })
);

export default store;
