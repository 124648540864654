import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchlogindata } from "./redux/action";
import { useNavigate } from "react-router-dom";
import cookie from "cookie";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showerror, setshowerror] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const setCookie = (thisisvalue) => {
    const cookieValue = cookie.serialize("jogwebtr", thisisvalue, {
      maxAge: 60 * 60 * 24, // 1 day
      path: "/",
    });
    document.cookie = cookieValue;
  };
  const loginfn = async () => {
    if (password.length !== 0 || email.length !== 0) {
      const apiUrl = "https://api.jogkart.com/jogkart/admin/login_admin.php";
      const requestBody = {
        email: email,
        password: password,
      };
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
        if (response.ok) {
          const userData = await response.json();
          console.log(userData.userid);
          setCookie(userData.userid);
          if (userData.status === "success" && userData.message === "success") {
            dispatch(fetchlogindata(userData));
            // Successful login, handle accordingly (e.g., redirect)
            navigate(`/home/`);
            // console.log("Login successful!");
            setshowerror(false);
          } else {
            setshowerror(true);
          }
        } else {
          // Handle login error
          console.error("Login failed");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("invalid crediantel");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ width: "100%" }} className="">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 20,
          }}
        >
          <div style={{ width: "20%" }}>Email</div>
          <input
            onChange={(event) => setemail(event.target.value)}
            className="wdth60ps hgt40"
            type="email"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 20,
          }}
        >
          <div style={{ width: "20%" }}>Password</div>
          <input
            onChange={(event) => setpassword(event.target.value)}
            className="wdth60ps hgt40"
            type="password"
            id="inputPassword2"
            placeholder="Password"
          />
        </div>
        <div style={{ color: "red" }}>
          {showerror && "Password Or Username Wrong"}
        </div>
        <div className="col-auto">
          <button
            onClick={loginfn}
            style={{
              backgroundColor: "black",
              color: "white",
              fontWeight: "600",
              padding: 10,
              width: 80,
              borderRadius: 5,
            }}
            type="submit"
            className="btn btn-primary mb-3"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
